import React, { ReactElement, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { defaultCall, Call } from '../../models/Call';
import { CompanyDetails } from "../../models/CompanyDetails";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCompanyDetails } from '../../store/slices/companyDetailsSlice';
import { setLoading } from '../../store/slices/loaderSlice';
import { setDisplay } from '../../store/slices/navItems';
import NoteBox from '../shared/NoteBox';
import TaskBox from '../shared/TaskBox';
import TicketBox from '../shared/TicketBox';
import CallService from "../../services/Call.Dataservice";
import { setError } from '../../store/slices/errorSlice';
import { formatToUtcDate } from '../../utils/dateFormat';

interface Props {
    companyDetails: CompanyDetails;
    showBackLink: boolean;
}

const DisplayCompanyInfos: React.FunctionComponent<Props> = ({companyDetails, showBackLink}: Props) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials);
    const companyDetailsState = useAppSelector((rootState) => rootState.companyDetails)
    const navItems = useAppSelector((rootState) => rootState.navItems);
    const phone = useAppSelector((rootState) => rootState.phone)

    if (companyDetails == null){
        companyDetails = companyDetailsState;
    }
    const hubSpotLink = `https://app.hubspot.com/contacts/${companyDetails.portalId}/company/${companyDetails.id}/`;
    const navigate = useNavigate();
      
    const logCall = () => {
        dispatch(setLoading(true));
        const call: Call = defaultCall;
        call.body = `Logged call with ${companyDetails.name} by ROGER365.io (Contact Center)`;
        call.callFromNumber = phone.number.replace("tel:", "");
        call.timeStamp = formatToUtcDate(new Date());
        CallService.save(credentials.client, credentials.password, credentials.portalId, companyDetails.id, 2, call).then((response) => {
            if(response != null){
                dispatch(setLoading(false));
            }
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(e.message));
        });
        dispatch(setLoading(false));
    }

    useEffect(() => {
        dispatch(setDisplay(true));
        dispatch(setCompanyDetails(companyDetails));
        logCall();
    }, [])

    const showCurrentBox = () : ReactElement => {
        switch (navItems.currentBox) {
            case 1:
                return <NoteBox></NoteBox>
            case 2:
                return <TicketBox></TicketBox>
            case 3:
                return <TaskBox></TaskBox>
        }
        return <></>;   
    }

    return(
        <div className="container-lg">
            <div className="row">
                <div className="col-6 col-md-5 col-lg-4">
                    <div>{companyDetails.name}</div>
                    <div>{companyDetails.streetAddress}</div>
                    <div>{companyDetails.postalCode ? companyDetails.postalCode : null}{companyDetails.postalCode ? " " : null}{companyDetails.city ? companyDetails.city : null}</div>
                    {companyDetails.email != "" && <div className='pt-2'><a href={`mailto:${companyDetails.email}`}>{companyDetails.email}</a></div>}
                </div>
                <div className="col-6 col-md-7 col-lg-8">
                {Object.entries(companyDetails.extendedProperties).map(([key, value], index) => {
                    return(
                        <div className="row" key={index}>
                            <div className="col-12">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{key}:</td>
                                            <td>{value}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
            <div className="row">
                <div className="d-flex pt-3">
                    { showBackLink ? <div><Link className="btn" role="button" onClick={() => navigate(-1)} to="">Back to company list</Link></div> : null }
                    <div className="ms-auto"><a className="btn" role="button" href={hubSpotLink} target="_blank">Go to CRM</a></div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pt-5 pb-5">
                    {showCurrentBox()}
                </div>
            </div>
        </div>
    );
}
export default DisplayCompanyInfos


import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContactDetails } from "../../models/ContactDetails";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDialog } from "../../store/slices/dialogSlice";
import { setLoading } from "../../store/slices/loaderSlice";
import { setCurrentBox } from "../../store/slices/navItems";
import { setError } from "../../store/slices/errorSlice";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContactDataService from "../../services/Contact.Dataservice";
import OwnerDataService from "../../services/Owner.Dataservice";
import DropdownListControl from "./DropdownList";
import { ActionMeta, SingleValue } from "react-select";
import { DropdownOption } from "../../models/DropdownOption";

const ContactBox: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials);
    const navItems = useAppSelector((rootState) => rootState.navItems);
    const phone = useAppSelector((rootState) => rootState.phone);
    const [errorField, setErrorField] = useState<number[]>([]);
    const [optionsOwner, setOptionsOwner] = useState<DropdownOption[]>([]);
    const [className, setClassName] = useState<string>("hide");
    const [contact, setContact] = useState<ContactDetails>({
        id: 0,
        portalId: 0,
        firstName: "",
        lastName: "",
        companyName: "",
        streetAddress: "",
        postalCode: "",
        city: "",
        email: "",
        phoneNumber: "+" + phone.number.replace("tel:", "").trim(),
        mobilePhoneNumber: "",
        ownerId: 0,
        extendedProperties: {}
    });

    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContact({...contact, [event.target.name] : event.target.value});
        isValid();
    }

    const changeDropdown = (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
        setContact({...contact, [actionMeta?.name == undefined ? "" : actionMeta.name] : newValue?.value});
    }

    const saveData = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setLoading(true));
        dispatch(setDialog(""));
        if (isValid()) {
            ContactDataService.save(credentials.client, credentials.password, credentials.portalId, contact).then((response) => {
                if(response != null){
                    dispatch(setDialog("Contact created"));
                    dispatch(setCurrentBox(0));
                    navigate("/contact/" + response.data.id + "/false", { replace: true });
                }
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(e.message));
            });
        
        }
        dispatch(setLoading(false));
    };

    const onClose = () => {
        setContact({
            id: 0,
            portalId: 0,
            firstName: "",
            lastName: "",
            companyName: "",
            streetAddress: "",
            postalCode: "",
            city: "",
            email: "",
            phoneNumber: "+" + phone.number.replace("tel:", "").trim(),
            mobilePhoneNumber: '',
            ownerId: 0,
            extendedProperties: {}
        });
        dispatch(setCurrentBox(0));
    }

    const isValid = () => {
        var isValid = true;
        var err = [];
        setErrorField([]);
        if(contact.phoneNumber == "" && contact.mobilePhoneNumber == ""){
            err.push(0);
            isValid = false;
        }
        if(contact.firstName == ""){
            err.push(1);
            isValid = false;
        }
        if(contact.lastName == ""){
            err.push(2);
            isValid = false;
        }
        if(contact.email == ""){
            err.push(3);
            isValid = false;
        }
        setErrorField(err);
        return isValid;
    }

    useEffect(() => {
        if(navItems.currentBox == 10){
            setClassName("show");
        }else{
            setClassName("hide");
        }

        OwnerDataService.get(credentials.client, credentials.password, credentials.portalId).then((response) => {
            if(response != null){
                const optionsOwner = response.data.map(owner => {
                    return {
                        label: owner.firstName + ' ' + owner.lastName + ' (' + owner.email + ')',
                        value: owner.id.toString()     
                    }                
                })
                setOptionsOwner(optionsOwner);
            }
            dispatch(setLoading(false));
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(e.message));
            dispatch(setLoading(false)); 
        });
    }, [navItems])

    return (
        <form onSubmit={(e) => saveData(e)}>
            <div className={className}>
                <div className="box">
                    <div className="communicator-header">
                        <div className="header-inner"> 
                            <div className="d-flex">
                                <div className="me-auto">Create contact</div>  
                                <Link to="" onClick={() => onClose()}><div className="close">×</div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="phoneNumber" className={"form-control" + (errorField.indexOf(0) > -1 ? " error" : "")} placeholder="Phone number *" defaultValue={"+" + phone.number.replace("tel:", "").trim()} maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="mobilePhoneNumber" className={"form-control" + (errorField.indexOf(0) > -1 ? " error" : "")} placeholder="Mobile Phone number" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="firstName" className={"form-control" + (errorField.indexOf(1) > -1 ? " error" : "")} placeholder="First name *" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="lastName" className={"form-control" + (errorField.indexOf(2) > -1 ? " error" : "")} placeholder="Last name *" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="companyName" className="form-control" placeholder="Company name " maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="streetAddress" className="form-control" placeholder="Street address " maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="postalCode" className="form-control" placeholder="Postal code " maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="city" className="form-control" placeholder="City " maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="email" className={"form-control" + (errorField.indexOf(3) > -1 ? " error" : "")}  placeholder="Email *" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="ownerId" showError={false} pladeHolderText="Contact owner" options={optionsOwner} onChange={changeDropdown} selectedValue={{label: "", value: ""}} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row">
                            <div className="float-start">
                                <Button type="submit" className="btn" variant="link">Create or update contact</Button>&nbsp;&nbsp;
                                <Button type="reset" className="btn" variant="link">Reset</Button>
                            </div>
                        </div>
                        <div>
                            <div className="pt-2">If there is a contact with the same email address, it will be updated.</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default ContactBox


import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";

import PaymentLayout from '../../containers/landingpage/PaymentLayout';
import React from "react";

const Manual: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(setLoading(false)); 
    }, []);

    return (
        <PaymentLayout>
            <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12">
                        <h1>Manual Roger365 Connector for HubSpot</h1>
                    </div>
                    <div className="col-12 col-lg-8">
                        <p>Roger365 is a contact center solution which is naturally integrated with Microsoft Teams. The connector allows you to link Roger365 Contact Center with HubSpot. This way you can see in Roger 365 which of your HubSpot contacts is calling. Or you can create a HubSpot note, ticket or task directly from Roger365. Benefit from a 360° customer view and more efficient workflows for your employees.</p>
                        <h2>Setup Process</h2>
                        <p>If you want to connect Roger365 with HubSpot, you need the Roger365 Connector. Follow the instructions below to start using the connector:</p>
                    </div>
                    <div className="col-12 col-lg-8 text-center">
                        <h3>Purchase the Connector or start trial license</h3>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <ol>
                            <li>Visit the HubSpot Marketplace and search for the <a href="https://ecosystem.hubspot.com/marketplace/apps" target="_blank">Roger365 MS Teams Connector"</a></li>
                            <li>Click on "Install App</li>
                            <li>Choose whether you want to pay monthly or annually</li>
                            <li>Enter your payment information</li>
                            <li>Complete the purchase. The 14-day trial period will start now. You will be charged for the first time after the trial period ends. If you decide to cancel your subscription during the trial period, please contact <a href="mailto:support@chili.ch">support@chili.ch</a>.</li>
                        </ol>
                    </div>
                    <div className="col-12 col-lg-8 text-center">
                        <h3>Install the Connector</h3>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <ol>
                            <li>
                                After purchasing you will be able to install the connector. It will be automatically added to your HubSpot account.<br /><br />
                                If you lost the connection to the steps on the screen, you will receive an email with your personal link for installation. Just open your personal link and install the connector app. 
                            </li>
                            <li>You will receive a mail with your personal activation URL (please do not open it, but follow the next steps).</li>
                        </ol>
                    </div>
                    <div className="col-12 col-lg-8 text-center">
                        <h3>Activate the Connector in Roger365</h3>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <ol>
                            <li>Open your <a href="https://admin.roger365.io/" target="_blank">Roger365 Admin Portal</a>.</li>
                            <li>
                                Go to the "Contact Center" tab and select "Queues".<br />
                                <img src={require("../../assets/img/landingpage/roger365_menu.png")} className="img-fluid" style={{width: 200}} />
                            </li>
                            <li>
                                Edit your queue<br />
                                <img src={require("../../assets/img/landingpage/roger365_queues.png")} className="img-fluid" style={{border: "1px solid black"}} />
                            </li>
                            <li>
                                Add your following personal URL (received by Mail) under "PowerFrame".<br />
                                <img src={require("../../assets/img/landingpage/roger365_powerframe.png")} className="img-fluid" style={{width: 220, border: "1px solid black"}} />
                            </li>
                            <li>Repeat steps 3 and 4 for any additional queues.</li>
                        </ol>
                    </div>
                    <div className="col-12 col-lg-8 text-center">
                        <h3>Use the Connector</h3>
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <p>
                            It's all done. You will see the HubSpot functions on the right side of your Roger365 interface whenever someone calls.<br />
                            <img src={require("../../assets/img/landingpage/roger365_contact_center_with_powerframe.png")} className="img-fluid" style={{width: 500, border: "1px solid black"}} />
                        </p>
                        <p>If you have any questions, please contact us at <a href="mailto:support@chili.ch">support@chili.ch</a>.</p>
                        <p>Enjoy the benefits of our Roger365 Connector for HubSpot.</p>
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default Manual;
import React, { ReactNode } from 'react'
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';

interface Props {
    children?: ReactNode
}

const PaymentLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const error = useAppSelector((rootState) => rootState.error);
    const location = useLocation();    
    require("./PaymentLayout.css");

    return (
        <>
            {
                <header>
                    <div id="header">
                        <div className="component header darkHeader">
                            <div className="component-content">
                                <div className="container-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-4">
                                        <div className="left-header">
                                            {location.pathname == "/" ? (
                                                null
                                            ) : (
                                                <a className="btn-primary" role="button" href="/">Get&nbsp;started</a>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-4 text-center">
                                            <a href="/">
                                                <img src={require("../../assets/img/landingpage/logo_roger365.png")} className="logo img-fluid d-lg-none" />
                                                <img src={require("../../assets/img/landingpage/roger365-Hubspot-CC.png")} className="logo d-none d-lg-block" />
                                            </a>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div className="right-header">
                                                <a className="btn-primary" role="button" href="/manual">Manual</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            }
            {error.errorMsg ?
                <main>
                    <div className="container-lg">
                        <Alert className='mt-4' variant="danger">{error.errorMsg}</Alert>
                    </div>
                </main>
                :
                <main>
                    {children}
                </main>
            }
            {
                <footer className="footer mt-auto py-4 py-md-5">
                    <div id="footer mt-auto py-3">
                        <div className="component footer">
                            <div className="component-content">
                                <div className="container">
                                    <div className="foot-middle">
                                        <div className="row">
                                            <div className="col-md-3 pb-3">
                                                <h2>Our office</h2>
                                                <p>
                                                    Chili Digital AG<br/>
                                                    Klausstrasse 43<br/>
                                                    8008 Zürich
                                                </p>
                                            </div>
                                            <div className="col-md-3 pb-5">
                                                <h2>Get in touch</h2>
                                                <a href="tel:+41443159000">+41 44 315 90 00</a><br />
                                                <a href="mailto:info@chili.ch">info@chili.ch</a>
                                            </div>
                                            <div className="col-md-3">
                                                <h2>About us</h2>
                                                <a href="/privacy-policy">Privacy Policy</a><br />
                                                <a href="/terms-of-service">Terms of Service</a>
                                            </div>
                                        </div>
                                        <div className="foot-bottom text-center">
                                            © Copyright {new Date().getFullYear()} All Rights Reserved.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> 
            }
        </>
    );
}

export default PaymentLayout;
import { Editor } from "@tinymce/tinymce-react";
import React from "react";

interface Props {
    pladeHolderText: string;
}

export default function EditorControl({pladeHolderText}: Props) {
    const onEditorChange = (content: any) => {
        localStorage.setItem("editor-content", content);
    };

    const onLoadContent = () => {
        require("./Editor.css");
    };
  
    return (
        <div>
            <Editor
                apiKey="r59cu0bs0xp0uk4zc7m5w648cam3dgp1cpgtc27xkory3gvz"
                init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: pladeHolderText,
                    height: 200,
                    menubar: false,
                    toolbar: "undo redo | bold italic underline | forecolor backcolor | alignleft aligncenter alignright | link | numlist bullist ",
                    plugins: "link lists",
                    link_title: false,
                    default_link_target: "_blank",
                    toolbar_location: "bottom",
                    content_style: "body { margin: 0 }",
                }}
                onEditorChange={onEditorChange}
                onInit={onLoadContent}
            />
        </div>
    );
}
  
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDialog } from "../../store/slices/dialogSlice";
import { setLoading } from "../../store/slices/loaderSlice";
import { setCurrentBox } from "../../store/slices/navItems";
import { setError } from "../../store/slices/errorSlice";
import { DropdownOption } from "../../models/DropdownOption";
import { Pipeline } from "../../models/Pipeline";
import { ActionMeta, SingleValue } from "react-select";
import { Button } from "react-bootstrap";
import { Ticket } from "../../models/Ticket";
import TextareaAutosize from 'react-textarea-autosize';
import DropdownListControl from "./DropdownList";
import OwnerDataService from "../../services/Owner.Dataservice";
import PipelineDataService from "../../services/Pipeline.Dataservice";
import TicketService from "../../services/Ticket.Dataservice";

const TicketBox: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials);
    const contactDetailsState = useAppSelector((rootState) => rootState.contactDetails);
    const companyDetailsState = useAppSelector((rootState) => rootState.companyDetails);
    const navItems = useAppSelector((rootState) => rootState.navItems);
    const [className, setClassName] = useState<string>("hide");
    const [optionsOwner, setOptionsOwner] = useState<DropdownOption[]>([]);
    const [optionsPipeline, setOptionsPipeline] = useState<DropdownOption[]>([]);
    const [optionsStage, setOptionsStage] = useState<DropdownOption[]>([]);
    const [pipelines, setPipelines] = useState<Pipeline[]>([]);
    const [selectedOptionStage, setselectedOptionStage] = useState<DropdownOption>({label: "", value: ""});
    const [errorField, setErrorField] = useState<number[]>([]);
    const [ticket, setTicket] = useState<Ticket>({
        id: 0,
        ticketName: "",
        pipelineId : "",
        stageId : "",
        description: "",
        ownerId: "0",
        priority: ""
    });

    const optionsPriority: DropdownOption[] = [
        { value: "LOW", label: "Low" },
        { value: "MEDIUM", label: "Medium" },
        { value: "HIGH", label: "High" }
    ];

    const saveData = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setLoading(true));
        dispatch(setDialog(""));
        if (isValid()) {
            if(contactDetailsState.id != 0){
                TicketService.save(credentials.client, credentials.password, credentials.portalId, contactDetailsState.id, 1, ticket).then((response) => {
                    if(response != null){
                        dispatch(setDialog("Ticket created"));
                        dispatch(setCurrentBox(0));
                    }
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(e.message));
                });
            }else{
                TicketService.save(credentials.client, credentials.password, credentials.portalId, companyDetailsState.id, 2, ticket).then((response) => {
                    if(response != null){
                        dispatch(setDialog("Ticket created"));
                        dispatch(setCurrentBox(0));
                    }
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(e.message));
                });
            }
        }
        dispatch(setLoading(false));
    };

    const isValid = () => {
        var isValid = true;
        var err : number[] = [];
        setErrorField([]);
        if(ticket.ticketName == ""){
            err.push(0);
            isValid = false;
        }
        if(ticket.pipelineId == ""){
            err.push(1);
            isValid = false;
        }
        if(ticket.stageId == ""){
            err.push(2);
            isValid = false;
        }
        setErrorField(err);
        return isValid;
    }

    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTicket({...ticket, [event.target.name] : event.target.value});
        isValid();
    }

    const changeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTicket({...ticket, [event.target.name] : event.target.value});
        isValid();
    }

    const changeDropdown = (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
        setTicket({...ticket, [actionMeta?.name == undefined ? "" : actionMeta.name] : newValue?.value});
        isValid();
    }
 
    const fillStages = (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
        changeDropdown(newValue, actionMeta);
        const pipelineId = Number(newValue?.value);
        const stages = pipelines.filter(a => a.id == pipelineId)[0].stages.map(stage => {
            return {
                label: stage.label,
                value: stage.id.toString()   
            }    
        });
        if(stages.length > 0){
            setOptionsStage(stages);
            setTicket({...ticket, pipelineId: pipelineId.toString(), stageId : stages[0].value});
            setselectedOptionStage(stages[0]);
        }
    }

    const changeStages = (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
        changeDropdown(newValue, actionMeta);
        if(newValue != null){
            setselectedOptionStage({label: newValue.label, value: newValue.value});
        }
    }

    useEffect(() => {
        if(navItems.currentBox == 2){
            setClassName("show");
        }else{
            setClassName("hide");
        }

        PipelineDataService.get(credentials.client, credentials.password, credentials.portalId).then((response) => {
            if(response != null){
                setPipelines(response.data);
                const optionsPipeline = response.data.map(pipeline => {
                    return {
                        label: pipeline.label,
                        value: pipeline.id.toString()   
                    }        
                })
                setOptionsPipeline(optionsPipeline);
            }
            dispatch(setLoading(false));
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(e.message));
            dispatch(setLoading(false)); 
        });

        OwnerDataService.get(credentials.client, credentials.password, credentials.portalId).then((response) => {
            if(response != null){
                const optionsOwner = response.data.map(owner => {
                    return {
                        label: owner.firstName + ' ' + owner.lastName + ' (' + owner.email + ')',
                        value: owner.id.toString()     
                    }                
                })
                setOptionsOwner(optionsOwner);
            }
            dispatch(setLoading(false));
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(e.message));
            dispatch(setLoading(false)); 
        });

        if(ticket.ticketName != ""){
            isValid();
        }
    }, [ticket])

    return (
        <form onSubmit={(e) => saveData(e)}>
            <div className={className}>
                <div className="box">
                    <div className="communicator-header">
                        <div className="header-inner"> 
                            <div className="d-flex">
                                <div className="me-auto">Ticket</div>  
                                <Link to="" onClick={() => dispatch(setCurrentBox(0))}><div className="close">×</div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7">
                                <h5>Associate ticket with {contactDetailsState.id != 0 ? contactDetailsState.firstName + " " + contactDetailsState.lastName : companyDetailsState.name}</h5>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="ticketName" className={"form-control" + (errorField.indexOf(0) > -1 ? " error" : "")} placeholder="Ticket name *" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="pipelineId" showError={(errorField.indexOf(1) > -1 ? true : false)} pladeHolderText="Pipeline *" options={optionsPipeline} onChange={fillStages} selectedValue={{label: "", value: ""}} isClearable={false}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="stageId" showError={(errorField.indexOf(2) > -1 ? true : false)} pladeHolderText="Ticket status *" options={optionsStage} onChange={changeStages} selectedValue={selectedOptionStage} isClearable={false}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-4">
                                <TextareaAutosize name="description" className="form-control" placeholder="Ticket description" maxLength={2000} onChange={e => {changeTextarea(e)}} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="ownerId" showError={false} pladeHolderText="Ticket owner" options={optionsOwner} onChange={changeDropdown} selectedValue={{label: "", value: ""}} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="priority" showError={false} pladeHolderText="Priority" options={optionsPriority} onChange={changeDropdown} selectedValue={{label: "", value: ""}} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div>
                            <Button type="submit" className="btn" variant="link">Create ticket</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default TicketBox

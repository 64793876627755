import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionMeta, SingleValue } from "react-select";
import { DropdownOption } from "../../models/DropdownOption";
import { Task } from "../../models/Task";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDialog } from "../../store/slices/dialogSlice";
import { setLoading } from "../../store/slices/loaderSlice";
import { setCurrentBox } from "../../store/slices/navItems";
import { getTimes } from "../../utils/lists";
import { Button } from "react-bootstrap";
import { setError } from "../../store/slices/errorSlice";
//import { formatToUtcDate } from "../../utils/dateFormat";
import DropdownListControl from "./DropdownList";
import EditorTinyMc from "./Editor";
import DatePicker from "react-datepicker";
import TaskService from "../../services/Task.Dataservice";
import OwnerDataService from "../../services/Owner.Dataservice";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import React from "react";

const TicketBox: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials);
    const contactDetailsState = useAppSelector((rootState) => rootState.contactDetails);
    const companyDetailsState = useAppSelector((rootState) => rootState.companyDetails);
    const navItems = useAppSelector((rootState) => rootState.navItems);
    const [className, setClassName] = useState<string>("hide");
    const [optionsOwner, setOptionsOwner] = useState<DropdownOption[]>([]);
    const [optionsReminder, setoptionsReminder] = useState<DropdownOption[]>([]);
    const [errorField, setErrorField] = useState<number[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [task, setTask] = useState<Task>({
        id: 0,
        taskname: "",
        description: "",
        ownerId: "0",
        priority: "",
        type: "",
        dueDate: null,
        dueTime: "",
        reminder: "",
        repeatType: "",
        repeatNumber: null
    });

    const optionsType: DropdownOption[] = [
        { value: "TODO", label: "To-do" },
        { value: "CALL", label: "Call" },
        { value: "EMAIL", label: "Email" }
    ];

    const optionsPriority: DropdownOption[] = [
        { value: "LOW", label: "Low" },
        { value: "MEDIUM", label: "Medium" },
        { value: "HIGH", label: "High" }
    ];

    const optionsDueTime: DropdownOption[] = getTimes(15);

   /*  const optionsRepeatType: DropdownOption[] = [
        { value: "DAILY", label: "Day(s)" },
        { value: "WEEKLY", label: "Week(s)" },
        { value: "MONTHLY", label: "Month(s)" },
        { value: "YEARLY", label: "Year(s)" }
    ]; */

    const optionsReminderList: DropdownOption[] = [
        { value: "[{\"unit\":\"MINUTES\",\"count\":0}]", label: "At task due time" },
        { value: "[{\"unit\":\"MINUTES\",\"count\":30}]", label: "30 minutes before" },
        { value: "[{\"unit\":\"HOURS\",\"count\":1}]", label: "1 hour before" },
        { value: "[{\"unit\":\"DAYS\",\"count\":1}]", label: "1 day before" },
        { value: "[{\"unit\":\"WEEKS\",\"count\":1}]", label: "1 week before" }
    ];

    const saveData = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setLoading(true));
        dispatch(setDialog(""));
        if (isValid()) {
            const editorContent = localStorage.getItem("editor-content");
            task.description = editorContent ?? "";
            if(contactDetailsState.id != 0){
                TaskService.save(credentials.client, credentials.password, credentials.portalId, contactDetailsState.id, 1, task).then((response) => {
                    if(response != null){
                        dispatch(setDialog("Task created"));
                        localStorage.setItem("editor-content", "")
                        dispatch(setCurrentBox(0));
                    }
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(e.message));
                });
            }else{
                TaskService.save(credentials.client, credentials.password, credentials.portalId, companyDetailsState.id, 2, task).then((response) => {
                    if(response != null){
                        dispatch(setDialog("Task created"));
                        localStorage.setItem("editor-content", "")
                        dispatch(setCurrentBox(0));
                    }
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(e.message));
                });
            }
        }
        dispatch(setLoading(false)); 
    }

    const isValid = () => {
        var isValid = true;
        var err : number[] = [];
        setErrorField([]);
        if(task.taskname == ""){
            err.push(0);
            isValid = false;
        }
        if(task.type == ""){
            err.push(1);
            isValid = false;
        }
        if(task.priority == ""){
            err.push(2);
            isValid = false;
        }
        if(task.dueDate == null){
            err.push(3);
            isValid = false;
        }
        if(task.dueTime == ""){
            err.push(4);
            isValid = false;
        }
        setErrorField(err);
        return isValid;
    }

    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTask({...task, [event.target.name] : event.target.value});
        isValid();
    }

    const changeDropdown = (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
        setTask({...task, [actionMeta?.name == undefined ? "" : actionMeta.name] : newValue?.value});
        isValid();
    }

    const onSelectDate = (date: Date) => {
        setTask({...task, "dueDate" : date});
        const today = new Date()
        const tomorrow = new Date(today)
        const week = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        week.setDate(today.getDate() + 7)
        if(date.getTime() == new Date().getTime()){
            optionsReminderList.splice(3);
            optionsReminderList.splice(4);
        }
        if(date.getTime() < week.getTime()){
            optionsReminderList.splice(4);
        }
        if(date.getTime() < tomorrow.getTime()){
            optionsReminderList.splice(3);
            optionsReminderList.splice(4);
        }
        setoptionsReminder(optionsReminderList);
        isValid();
    }

    const onClose = () => {
        setTask({
            id: 0,
            taskname: "",
            description: "",
            ownerId: "0",
            priority: "",
            type: "",
            dueDate: null,
            dueTime: "",
            reminder: "",
            repeatType: "",
            repeatNumber: null
         });
         dispatch(setCurrentBox(0));
     }

    useEffect(() => {
        setStartDate(null);
        if(navItems.currentBox == 3){
            setClassName("show");
        }else{
            setClassName("hide");
        }

        setoptionsReminder(optionsReminderList);

        OwnerDataService.get(credentials.client, credentials.password, credentials.portalId).then((response) => {
            if(response != null){
                const optionsOwner = response.data.map(owner => {
                    return {
                        label: owner.firstName + ' ' + owner.lastName + ' (' + owner.email + ')',
                        value: owner.id.toString()     
                    }                
                })
                setOptionsOwner(optionsOwner);
            }
            dispatch(setLoading(false));
            dispatch(setError(""));
        }).catch((e: Error) => {
            dispatch(setError(e.message));
            dispatch(setLoading(false)); 
        });
    }, [])

    return (
        <form onSubmit={(e) => saveData(e)}>
            <div className={className}>
                <div className="box">
                    <div className="communicator-header">
                        <div className="header-inner"> 
                            <div className="d-flex">
                                <div className="me-auto">Task</div>  
                                <Link to="" onClick={() => onClose()}><div className="close">×</div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7">
                                <h5><h5>Associate task with {contactDetailsState.id != 0 ? contactDetailsState.firstName + " " + contactDetailsState.lastName : companyDetailsState.name}</h5></h5>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <input type="text" name="taskname" className={"form-control" + (errorField.indexOf(0) > -1 ? " error" : "")} placeholder="Enter your task *" maxLength={150} onChangeCapture={changeInput} />
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-7 col-md-4 col-lg-4 pb-3">
                                <DropdownListControl name="type" showError={(errorField.indexOf(1) > -1 ? true : false)} pladeHolderText="Type *" options={optionsType} selectedValue={{ label: "", value: "" }} onChange={changeDropdown} isClearable={true}></DropdownListControl>
                            </div>
                            <div className="col-5 col-md-4 col-lg-3 pb-3">
                                <DropdownListControl name="priority" showError={(errorField.indexOf(2) > -1 ? true : false)} pladeHolderText="Priority *" options={optionsPriority} selectedValue={{ label: "", value: "" }} onChange={changeDropdown} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-6 col-md-4 col-lg-4 pb-3">
                                <DatePicker name="dueDate" className={"form-control" + (errorField.indexOf(3) > -1 ? " error" : "")}  minDate={moment().toDate()} selected={startDate} onChange={date => date && setStartDate(date)} onSelect={onSelectDate} placeholderText="Due date *" dateFormat="dd.MM.yyyy" onChangeRaw={changeInput} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-3 pb-3">
                                <DropdownListControl name="dueTime" showError={(errorField.indexOf(4) > -1 ? true : false)} pladeHolderText="Due time *" options={optionsDueTime} selectedValue={{ label: "", value: "" }} onChange={changeDropdown} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="ownerId" showError={false} pladeHolderText="Ticket owner" options={optionsOwner} onChange={changeDropdown} selectedValue={{label: "", value: ""}} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <DropdownListControl name="reminder" showError={false} pladeHolderText="Reminder" options={optionsReminder} onChange={changeDropdown} selectedValue={{label: "", value: ""}} isClearable={true}></DropdownListControl>
                            </div>
                        </div>
                        {/* <div className="row g-5 align-items-center">
                            <div className="col-4 col-md-3 col-lg-2 pb-3">
                                Repeat every 
                            </div>
                            <div className="col-3 col-md-2 col-lg-2 pb-3">
                            <input type="number" name="repeatNumber" className="form-control" placeholder="Number" maxLength={150} onChangeCapture={changeInput}></input>
                            </div>
                            <div className="col-5 col-md-3 col-lg-3 pb-3">
                            <DropdownListControl name="repeatType" showError={(errorField.indexOf(5) > -1 ? true : false)} pladeHolderText="Repeat type" options={optionsRepeatType} selectedValue={{ label: "", value: "" }} onChange={changeDropdown}></DropdownListControl>
                            </div>
                        </div>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                Default reminder. You can customize your default settings. <a href={`https://app.hubspot.com/settings/${portalId}/user-preferences/tasks`} target="_blank">Go to settings</a>
                            </div>
                        </div> */}
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-8 col-lg-7 pb-3">
                                <div className="editor">
                                    <EditorTinyMc pladeHolderText="Notes"></EditorTinyMc>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div>
                            <Button type="submit" className="btn" variant="link">Create task</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default TicketBox

import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";

import PaymentLayout from '../../containers/landingpage/PaymentLayout';

const TermsOfService: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    return (
        <PaymentLayout>
            <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12">
                        <h1>Terms of Service</h1>
                    </div>
                    <div className="col-12 col-lg-8">
                        <p>
                            These terms and conditions ("agreement") set forth the general terms and conditions of your use of the "Roger365 Connector for HubSpot" ("app", "service" or “subscription service”) and any of its related products and services (collectively, "services"). This agreement is legally binding between you ("user", "you" or "your") and this operator ("operator", "we", "us" or "our"). By accessing and using the app and services, you acknowledge that you have read, understood, and agree to be bound by the terms of this agreement. If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms "user", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the app and services. You acknowledge that this agreement is a contract between you and the operator, even though it is electronic and is not physically signed by you, and it governs your use of the app and services.
                        </p>
                        <h3>Access</h3>
                        <p>
                            During the subscription term, we will provide your users access to use the subscription service as described in this agreement and the applicable order.  You must ensure that all access, use and receipt by your users is subject to and in compliance with this agreement. We might provide some or all elements of the subscription service through third party service providers.
                        </p>
                        <h3>Prohibited and unauthorized use </h3>
                        <p>
                            You must be 18 years of age (or 20 years of age, if you are subject to the laws of Japan) or older to use the subscription service. You may not use the subscription service if you are legally prohibited from receiving or using the subscription service under the laws of the country in which you are resident or from which you access or use the subscription service.
                        </p>
                        <h3>Modifications </h3>
                        <p>
                            We modify the subscription service from time to time, including by adding or deleting features and functions, in an effort to improve your experience.
                        </p>
                        <h3>Customer support</h3>
                        <p>
                            For information on the customer support terms that apply to your subscription, please refer to the general terms of Chili Digital AG.
                        </p>
                        <h3>Free trial </h3>
                        <p>
                            If you register for a free trial, we will make the applicable subscription service available to you on a trial basis free of charge until the earlier of (a) the end of the free trial period (if not terminated earlier) or (b) the start date of your paid subscription.
                        </p>
                        <h3>Sensitive information</h3>
                        <p>
                            You acknowledge that the subscription services have not been designed to process or manage sensitive information. We will not have and we specifically disclaim any liability that may result from your use of the subscription service to collect, process or manage sensitive information.
                        </p>
                        <h3>Links to other resources</h3>
                        <p>
                            Although the app and services may link to other resources (such as programs, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the app and services. Your linking to any other off-app resources is at your own risk.
                        </p>
                        <h3>Payment</h3>
                        <p>
                            As you are paying by credit card, you authorize us to charge your credit card or bank account for all fees payable during the subscription term. You further authorize us to use a third party to process payments, and consent to the disclosure of your payment information to such third party.
                        </p>
                        <h3>Payment Information</h3>
                        <p>
                            You will keep your contact information, billing information and credit card information (where applicable) up to date. All payment obligations are non-cancelable and all amounts paid are non-refundable, except as specifically provided for in this agreement. All fees are due and payable in advance throughout the subscription term.
                        </p>
                        <h3>Sales Tax</h3>
                        <p>
                            All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any taxes applicable to your use of the subscription service and performance of consulting services. You shall have no liability for any taxes based upon our gross revenues or net income. If you are located in the European Union, all fees are exclusive of any VAT and you represent that you are registered for VAT purposes in your member state. At our request, you will provide us with the VAT registration number under which you are registered in your member state.  If you do not provide us with a VAT registration number prior to your transaction being processed, we will not issue refunds or credits for any VAT that was charged. If you are subject to GST, all fees are exclusive of GST.  If you are located in Canada, all fees are exclusive of GST, PST and HST.
                        </p>
                        <h3>Term and renewal</h3>
                        <p>
                            Your initial subscription term will be for 12 months, unless otherwise specified in your order. The contract is renewed for a further 12 months at a time, unless otherwise specified in your order.
                        </p>
                        <h3>Notice of non-renewal</h3>
                        <p>
                            Unless otherwise specified in your order, to prevent renewal of your subscription, you or we must give written notice of non-renewal. The deadline for sending this notice is at least 3 months before expiry.
                        </p>
                        <h3>Intellectual property</h3>
                        <p>
                            This is an agreement for access to and use of the Subscription Service, and you are not granted a license to any software by this agreement. The Subscription Service and consulting services are protected by intellectual property laws, they belong to and are the property of us or our licensors (if any), and we retain all ownership rights to them. You agree not to copy, rent, lease, sell, distribute, or create derivative works based on the content, the subscription service, or the consulting services in whole or in part, by any means, except as expressly authorized in writing by us.
                        </p>
                        <h3>Changes and amendments</h3>
                        <p>
                            We reserve the right to modify this agreement or its terms relating to the app and services at any time, effective upon posting of an updated version of this agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.
                        </p>
                        <h3>Acceptance of these terms</h3>
                        <p>
                            You acknowledge that you have read this agreement and agree to all its terms and conditions. By accessing and using the app and services you agree to be bound by this agreement. If you do not agree to abide by the terms of this agreement, you are not authorized to access or use the app and services.
                        </p>
                        <h3>Contacting us</h3>
                        <p>
                            If you would like to contact us to understand more about this agreement or wish to contact us concerning any matter relating to it, you may send an email to <a href="mailto:info@chili.ch">info@chili.ch</a>.
                            <br /><br />
                            Zürich / 25.08.2022
                        </p>
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default TermsOfService;
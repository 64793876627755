import { http } from "../http-common";
import { Task } from "../models/Task";

class TaskDataService {
    async save(client: string, password: string, portalId: string, hubSpotId: number, entityId: number, task: Task) {
        if(task != null){
            const taskJson = JSON.stringify(task);
            return await http(client, password).post<Task>(`/hubspot/portal/${portalId}/task?hubSpotId=${hubSpotId}&entityId=${entityId}`, taskJson);
        }
        return null;
    }
}
export default new TaskDataService();
import { useEffect } from "react";
import PaymentLayout from "../../containers/landingpage/PaymentLayout";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";

const NotFound: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(setLoading(false)); 
    }, []);

    return (
        <PaymentLayout>
            <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12">
                        <h1>404 - Page not found</h1>
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default NotFound;
import { configureStore } from '@reduxjs/toolkit'
import companyDetailsSlice from './slices/companyDetailsSlice'
import contactDetailsSlice from './slices/contactDetailsSlice'
import credentialsSlice from './slices/credentialsSlice'
import dialogSlice from './slices/dialogSlice'
import errorSlice from './slices/errorSlice'
import loaderSlice from './slices/loaderSlice'
import navItemsSlice from './slices/navItems'
import phoneSlice from './slices/phoneSlice'

export const rootStore = configureStore({
  reducer: {
    contactDetails: contactDetailsSlice,
    companyDetails: companyDetailsSlice,
    credentials: credentialsSlice,
    dialog: dialogSlice,
    error: errorSlice,
    loader: loaderSlice,
    navItems: navItemsSlice,
    phone: phoneSlice
  },
})

export type RootState = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch
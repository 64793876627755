import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentBox } from "../../store/slices/navItems";
import { defaultNote, HubSpotNote } from "../../models/HubSpotNote";
import { defaultNoteProperty } from "../../models/NoteProperty";
import { formatToUtcDate } from "../../utils/dateFormat";
import { setError } from "../../store/slices/errorSlice";
import { setDialog } from "../../store/slices/dialogSlice";
import { setLoading } from "../../store/slices/loaderSlice";
import NoteDataService from "../../services/Note.Dataservice";
import EditorTinyMc from "./Editor";

const NoteBox: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials);
    const contactDetailsState = useAppSelector((rootState) => rootState.contactDetails);
    const companyDetailsState = useAppSelector((rootState) => rootState.companyDetails);
    const navItems = useAppSelector((rootState) => rootState.navItems);
    const [className, setClassName] = useState<string>("hide");

    const save = () => {
        dispatch(setLoading(true));
        dispatch(setDialog(""));
        const editorContent = localStorage.getItem("editor-content");
        const note: HubSpotNote = defaultNote;
        note.id = 10;
        note.properties = defaultNoteProperty;
        note.properties.noteBody = editorContent;
        note.properties.timestamp = formatToUtcDate(new Date());
        if(contactDetailsState.id != 0){
            NoteDataService.save(credentials.client, credentials.password, credentials.portalId, contactDetailsState.id, 1 ,note).then((response) => {
                if(response != null){
                    dispatch(setDialog("Note is saved"));
                    localStorage.setItem("editor-content", "")
                    dispatch(setCurrentBox(0));
                }
                dispatch(setLoading(false));
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(e.message));
                dispatch(setLoading(false)); 
            }); 
        }else{
            NoteDataService.save(credentials.client, credentials.password, credentials.portalId, companyDetailsState.id, 2 ,note).then((response) => {
                if(response != null){
                    dispatch(setDialog("Note is saved"));
                    localStorage.setItem("editor-content", "")
                    dispatch(setCurrentBox(0));
                }
                dispatch(setLoading(false));
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(e.message));
                dispatch(setLoading(false)); 
            }); 
        }
    }

    useEffect(() => {
        if(navItems.currentBox == 1){
            setClassName("show");
        }else{
            setClassName("hide");
        }
    })

    return (
        <div className={className}>
            <div className="box">
                <div className="communicator-header">
                    <div className="header-inner"> 
                        <div className="d-flex">
                            <div className="me-auto">Note</div>  
                            <Link to="" onClick={() => dispatch(setCurrentBox(0))}><div className="close">×</div></Link>
                        </div>
                    </div>
                </div>
                <div className="content-inner">
                    <div className="row g-5 align-items-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <h5>Associate note with {contactDetailsState.id != 0 ? contactDetailsState.firstName + " " + contactDetailsState.lastName : companyDetailsState.name}</h5>
                        </div>
                    </div>
                    <div className="editor">
                        <EditorTinyMc pladeHolderText="Start typing to leave a note..."></EditorTinyMc>
                    </div>
                </div>
                <div className="content-inner">
                    <div>
                        <Link className="btn" to="" onClick={save}>Create note</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoteBox

import { http } from "../http-common";
import { ContactDetails } from "../models/ContactDetails";

class ContactDataService {
    async get(client: string, password: string, portalId: string, phoneNumber: string) {
        if(phoneNumber != null){
            phoneNumber = phoneNumber.replace("tel:", "");
            return await http(client, password).get<Array<ContactDetails>>(`/hubspot/portal/${portalId}/contact/phonenumber/${phoneNumber}`);
        }
        return null;
    }

    async getById(client: string, password: string, portalId: string, contactId: string ) {
        return await http(client, password).get<ContactDetails>(`/hubspot/portal/${portalId}/contact/${contactId}`);
    }

    async save(client: string, password: string, portalId: string, contactDetails: ContactDetails) {
        if(contactDetails != null){
            const contactJson = JSON.stringify(contactDetails);
            return await http(client, password).post<ContactDetails>(`/hubspot/portal/${portalId}/contact`, contactJson);
        }
        return null;
    }
}
export default new ContactDataService();
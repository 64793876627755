import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Phone } from '../../models/Phone';

const initialState: Phone = {
    number: ""
}

export const phoneSlice = createSlice({
    name: 'phone',
    initialState,
    reducers: {
        setPhoneNumber: (state, action: PayloadAction<string>) => {
            state.number = action.payload;
        }
    },
})

export const { setPhoneNumber } = phoneSlice.actions
export default phoneSlice.reducer
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dialog } from '../../models/Dialog';

const initialState: Dialog = {
    message: ""
}

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        setDialog: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        }
    },
})

export const { setDialog } = dialogSlice.actions
export default dialogSlice.reducer
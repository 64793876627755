import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CompanyDetails } from "../../models/CompanyDetails";
import { useAppDispatch } from "../../store/hooks";
import { setDisplay } from "../../store/slices/navItems";

interface Props {
    companyDetailsList: CompanyDetails[];
}

const DisplayCompanyList: React.FunctionComponent<Props> = ({companyDetailsList}: Props) => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(setDisplay(false));
    }, [])

    return(
        <div className="container-lg">
            <h2>There are several companys with the same phone number</h2>
            {companyDetailsList.map((companyDetails, index) => {
                const detailLink = `/company/${companyDetails.id}`;
                return(
                    <div key={index}>
                        <Link to={detailLink}>{companyDetails.name}</Link>
                        {companyDetails.postalCode || companyDetails.city ? "," : null}
                        {companyDetails.postalCode ? " " + companyDetails.postalCode : null}
                        {companyDetails.city ? " " + companyDetails.city : null}
                    </div>
                )
            })}
        </div>
    );
}
export default DisplayCompanyList
import { DropdownOption } from "../models/DropdownOption";

export const getTimes = (minutesInterval: number) => {
    var x = minutesInterval;
    var times: DropdownOption[] = [];
    var tt = 0;

    for (var i=0;tt<24*60; i++) {
        var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        var mm = (tt%60); // getting minutes of the hour in 0-55 format
        times.push({value: ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), label:  ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2)})
        tt = tt + x;
    }
    return times;
}
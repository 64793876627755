import { http } from "../http-common";
import { Ticket } from "../models/Ticket";

class TicketDataService {
    async save(client: string, password: string, portalId: string, hubSpotId: number, entityId: number, ticket: Ticket) {
        if(ticket != null){
            const ticketJson = JSON.stringify(ticket);
            return await http(client, password).post<Ticket>(`/hubspot/portal/${portalId}/ticket?hubSpotId=${hubSpotId}&entityId=${entityId}`, ticketJson);
        }
        return null;
    }
}
export default new TicketDataService();
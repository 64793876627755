import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import DisplayCompanyInfos from '../components/company/DisplayCompanyInfos';
import DisplayCompanyList from '../components/company/DisplayCompanyList';
import DisplayContactInfos from '../components/contact/DisplayContactInfos';
import DisplayContactList from '../components/contact/DisplayContactList';
import ContactBox from '../components/shared/ContactBox';
import { CompanyDetails } from '../models/CompanyDetails';
import { ContactDetails } from '../models/ContactDetails';
import CompanyDataservice from '../services/Company.Dataservice';
import ContactDataservice from '../services/Contact.Dataservice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setError } from '../store/slices/errorSlice';
import { setLoading } from '../store/slices/loaderSlice';
import { setCurrentBox } from '../store/slices/navItems';
import Layout from './Layout';

const List: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials)
    const [searchParams] = useSearchParams();
    let params = useParams();
    const contactId = params.contactId;
    const companyId = params.companyId;
    const phoneNumber = searchParams.get('phonenumber');
    const [contactDetails, setContactDetails] = useState<ContactDetails[]>([]);
    const [companyDetails, setCompanies] = useState<CompanyDetails[]>([]);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);

    useEffect(() => {
        getContact();
        dispatch(setCurrentBox(0));
    }, [contactId, companyId]);

    const getContact = () => {
        dispatch(setLoading(true));
        if(phoneNumber == null){
            dispatch(setError("Phone number is missing"));
            dispatch(setLoading(false)); 
        } else {
            ContactDataservice.get(credentials.client, credentials.password, credentials.portalId, phoneNumber).then((response) => {
                if(response != null){
                    if(response.data.length == 0){
                        CompanyDataservice.get(credentials.client, credentials.password, credentials.portalId, phoneNumber).then((responseCompany) => {
                            if(responseCompany != null){
                                if(responseCompany.data.length == 0){
                                    setNoDataFound(true);
                                    dispatch(setLoading(false));
                                    dispatch(setError(""));
                                }else{
                                    setCompanies(responseCompany.data);
                                    dispatch(setLoading(false));
                                    dispatch(setError(""));
                                }
                            }
                        }).catch((e: Error) => {
                            dispatch(setError(e.message));
                            dispatch(setLoading(false)); 
                        }); 
                    }else{
                        setContactDetails(response.data);
                        dispatch(setLoading(false));
                        dispatch(setError(""));
                    }
                }
            }).catch((e: Error) => {
                dispatch(setError(e.message));
                dispatch(setLoading(false)); 
            });
        }
    }

    const clickHandler = (event: React.MouseEvent<HTMLAnchorElement>, id: number) => {
        event.preventDefault();
        dispatch(setCurrentBox(id));
    };

    const showCurrentBox = () : ReactElement => {
        return <ContactBox></ContactBox>;   
    }

    if(noDataFound){
        return (
            <Layout>
                <div className="container-lg">
                    <div className="row">
                        <h2>No contact or company found</h2>
                        <div><Link className="btn" role="btn" onClick={(event) => clickHandler(event, 10)} to={''}>Add new contact</Link></div>
                    </div>
                    <div className="row">
                        <div className="col-12 pt-5 pb-5">
                            {showCurrentBox()}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
    else
    {
        if(contactDetails.length > 1){
            return (
                <Layout>
                    <DisplayContactList contactDetailsList={contactDetails}></DisplayContactList>
                </Layout>
            )
        }else if(contactDetails.length == 1){
            return (
                <Layout>    
                    <DisplayContactInfos contactDetails={contactDetails[0]} showBackLink={false}></DisplayContactInfos>
                </Layout>
            )
        }else if(companyDetails.length > 1){
            return (
                <Layout>
                    <DisplayCompanyList companyDetailsList={companyDetails}></DisplayCompanyList>
                </Layout>
            )
        }else if(companyDetails.length == 1){
            return (
                <Layout>    
                    <DisplayCompanyInfos companyDetails={companyDetails[0]} showBackLink={false}></DisplayCompanyInfos>
                </Layout>
            )
        }else{
            return (
                <Layout>
                </Layout>
            )
        }
    }
}
export default List;
import { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";

import PaymentLayout from '../../containers/landingpage/PaymentLayout';

const PaymentError: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    let params = useParams();
    const errorId = params.errorId;

    useEffect(() => {
        dispatch(setLoading(false)); 
    }, [errorId]);

    const errorMessage = (errorId: string | undefined) : ReactElement => {
        switch (errorId) {
            case "no-valid-payment":
                return (
                    <>
                        <h1>Payment error</h1>
                        <p>The payment was not successful. Please try to purchase the app again, carefully checking your payment information.</p>
                        <p>In case of further problems please contact our support: <a href="https://www.chili.ch/support" target="_blank">https://www.chili.ch/support</a></p>
                    </>
                )
            case "already-used-payment":
                return (
                    <>
                        <h1>Payment error</h1>
                        <p>
                            The payment has already been used to install the app. Please check in HubSpot whether the app has already been linked. To do this, click on the Marketplace icon in the top right corner and then go to connected apps.
                            <br /><br />
                            <img src={require("../../assets/img/landingpage/hubspot_marketplace.png")} className="img-fluid" style={{width: 375, border: "1px solid black"}} />
                        </p>
                        <p>When you see the «Roger365 Microsoft Teams Connector» in the linked apps overview in HubSpot, you just need to link it to Roger365 admin center with your personal URL.</p>
                        <p>If you can’t find the «Roger365 Microsoft Teams Connector» in the linked apps in HubSpot, please contact our support: <a href="https://www.chili.ch/support" target="_blank">https://www.chili.ch/support</a></p>
                    </>
                )
            case "portalid-missing":
                return (
                    <>
                        <h1>Installation error</h1>
                        <p>No payment exists for your HubSpot portal. To use the Roger365 Microsoft Teams Connector, you must purchase it from the HubSpot marketplace.</p>
                        <p>In case of any problems please contact our support: <a href="https://www.chili.ch/support" target="_blank">https://www.chili.ch/support</a></p>
                    </>
                )
            case "already-connected-portal":
                return (
                    <>
                        <h1>Installation error</h1>
                        <p>
                            The app has already been installed. Please check in HubSpot whether the app has already been linked. To do this, click on the Marketplace icon in the top right corner and then go to connected apps.
                            <br /><br />
                            <img src={require("../../assets/img/landingpage/hubspot_marketplace.png")} className="img-fluid" style={{width: 375, border: "1px solid black"}} />
                        </p>
                        <p>When you see the «Roger365 Microsoft Teams Connector» in the linked apps overview in HubSpot, you just need to link it to Roger365 admin center with your personal URL.</p>
                        <p>If you can’t find the «Roger365 Microsoft Teams Connector» in the linked apps in HubSpot, please contact our support: <a href="https://www.chili.ch/support" target="_blank">https://www.chili.ch/support</a></p>
                    </>
                )
            case "bad-request":
            case "unpaid":
            case "no-payment-required":
            default:
                return (
                    <>
                        <h1>Error</h1>
                        <p>Unfortunately, an error has occurred. Please try again.</p>
                        <p>In case of further problems please contact our support: <a href="https://www.chili.ch/support" target="_blank">https://www.chili.ch/support</a></p>
                    </>
                )
        } 
    }

    return (
        <PaymentLayout>
             <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12 col-lg-8">
                        {errorMessage(errorId)}
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default PaymentError;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Credentials } from '../../models/Credentials';

const initialState: Credentials = {
    portalId: "",
    client: "",
    password: ""
}

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setPortalId: (state, action: PayloadAction<string>) => {
            state.portalId = action.payload;
        },
        setClient: (state, action: PayloadAction<string>) => {
            state.client = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        }
    },
})

export const { setPortalId, setClient, setPassword } = credentialsSlice.actions
export default credentialsSlice.reducer
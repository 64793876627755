import Select, { ActionMeta, SingleValue, StylesConfig } from "react-select";
import { CSSProperties } from "react";
import { DropdownOption } from "../../models/DropdownOption";
import React from "react";

interface Props {
    name: string;
    showError: boolean;
    pladeHolderText: string;
    options: DropdownOption[];
    onChange: (newValue: SingleValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => void;
    selectedValue: DropdownOption | any;
    isClearable: boolean;
}

export default function DropdownListControl({name, showError, pladeHolderText, options, onChange, selectedValue, isClearable}: Props) {     
    const selectedValueAttribute = selectedValue.value == "" ? {} : {value: selectedValue}
    const customControlStyles: CSSProperties = {
        fontFamily: "\"Avenir Next W02\", Helvetica, Arial, sans-serif",
        color: "rgb(51, 71, 91)",
        border: showError ? "1px solid #ff7a59" : "1px solid rgb(203, 214, 226)",
        backgroundColor: "rgb(245, 248, 250)",
        borderRadius: "3px",
        lineHeight: "22px",
        fontSize: "15px",
        padding: "2px 4px",
        minHeight: "40px",
        fontWeight: "400",
    };

    const customOptionStyles: CSSProperties = {
        fontFamily: "\"Avenir Next W02\", Helvetica, Arial, sans-serif",
        color: "rgb(51, 71, 91)",
        backgroundColor: "#fff"
    };

    type IsMulti = false;
    
    const selectStyle: StylesConfig<DropdownOption, IsMulti> = {
        control: (provided, state) => {
            return {
                ...provided,
                ...customControlStyles,
                border: showError ? "1px solid #ff7a59" : state.isFocused ? "1px solid rgba(0, 208, 228, 0.5)" : "",
                boxShadow: "none",
                "&:hover": {
                    border: showError ? "1px solid #ff7a59" : "1px solid rgb(203, 214, 226)",
                    backgroundColor: "rgb(245, 248, 250)",
                    outline: "0px",
                    boxShadow: "none"
                },
                "&:focus": {
                    border: showError ? "1px solid #ff7a59" : "1px solid rgba(0, 208, 228, 0.5)",
                    backgroundColor: "rgb(245, 248, 250)",
                    outline: "0px",
                    boxShadow: "none"
                },
                "&:active": {
                    border: showError ? "1px solid #ff7a59" : "1px solid rgba(0, 208, 228, 0.5)",
                    backgroundColor: "rgb(245, 248, 250)",
                    outline: "0px",
                    boxShadow: "none"
                }          
            };
        }, 
        option: (provided) => {
            return {
                ...provided,
                ...customOptionStyles,
                "&:hover": {
                    backgroundColor: "#e5f5f8;",
                }
            }   
        }
    };

    return (
        <>
            <Select name={name} options={options} styles={selectStyle} placeholder={pladeHolderText} onChange={onChange} {...selectedValueAttribute} isClearable={isClearable} />
        </>
    );
}
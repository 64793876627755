import { Routes, Route, useSearchParams } from "react-router-dom";
import Infos from "./containers/Infos";
import React, { ReactFragment, Suspense } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css'; 
import { Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { setClient, setPassword, setPortalId } from "./store/slices/credentialsSlice";
import List from "./containers/List";
import { setPhoneNumber } from "./store/slices/phoneSlice";
import Payment from "./components/landingpage/Payment";
import NotFound from "./components/landingpage/NotFound";
import PaymentConfirmation from "./components/landingpage/PaymentConfirmation";
import PaymentError from "./components/landingpage/PaymentError";
import Manual from "./components/landingpage/Manual";
import PrivacyPolicy from "./components/landingpage/PrivacyPolicy";
import TermsOfService from "./components/landingpage/TermsOfService";

const App: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const portalid = searchParams.get('portalid');
  const client = searchParams.get('client');
  const password = searchParams.get('pass');
  const phonenumber = searchParams.get('phonenumber');
  if(portalid != null){
    dispatch(setPortalId(portalid));
  }
  if(client != null){
    dispatch(setClient(client));
  }
  if(password != null){
    dispatch(setPassword(password));
  }
  if(phonenumber != null){
    dispatch(setPhoneNumber(phonenumber));
  }
  const loader = useAppSelector((rootState) => rootState.loader)
  const dialog = useAppSelector((rootState) => rootState.dialog)

  const renderLoader = (show: boolean): ReactFragment => {
    return (
      <div className={"loader-fullpage " + (show ? "isLoading" : "")}>
        <div className="loader-container">
          <Spinner animation="border"></Spinner>
        </div>
      </div>
    );
  }

  const renderDialog = (message: string): ReactFragment | null => {
    return (
      <div className="d-flex justify-content-center">
        <div className={"d-flex justify-content-center dialog" + (message ? " hide" : " hidden")}>{message}</div>
      </div>
    );
  }

  return (
    <Suspense fallback={renderLoader(true)}>
      <>
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/" element={<Payment />} />
            <Route path="/manual" element={<Manual />} />
            <Route path="/powerframe" element={<List />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
            <Route path="/payment-error/:errorId/" element={<PaymentError />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact/:contactId/" element={<Infos />} />
            <Route path="/contact/:contactId/:showBackLink" element={<Infos />} />
            <Route path="/company/:companyId" element={<Infos />} />
          </Routes>
          {renderLoader(loader.isLoading)}
          {renderDialog(dialog.message)}
      </>
    </Suspense>
  );
}

export default App;


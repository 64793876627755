import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ContactDetails } from "../../models/ContactDetails";
import { useAppDispatch } from "../../store/hooks";
import { setDisplay } from "../../store/slices/navItems";

interface Props {
    contactDetailsList: ContactDetails[];
}

const DisplayContactList: React.FunctionComponent<Props> = ({contactDetailsList}: Props) => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(setDisplay(false));
    }, [])

    return(
        <div className="container-lg">
            <h2>There are several contacts with the same phone number</h2>
            {contactDetailsList.map((contactDetails, index) => {
                const detailLink = `/contact/${contactDetails.id}`;
                return(
                    <div key={index}>
                        <Link to={detailLink}>{contactDetails.firstName} {contactDetails.lastName}</Link>
                        {contactDetails.postalCode || contactDetails.city ? "," : null}
                        {contactDetails.postalCode ? " " + contactDetails.postalCode : null}
                        {contactDetails.city ? " " + contactDetails.city : null}
                    </div>
                )
            })}
        </div>
    );
}
export default DisplayContactList
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavItems } from '../../models/NavItems';

const initialState: NavItems = {
    show: false,
    currentBox: 0
}

export const navItemsSlice = createSlice({
    name: 'navItems',
    initialState,
    reducers: {
        setDisplay: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload;
        },
        setCurrentBox: (state, action: PayloadAction<number>) => {
            state.currentBox = action.payload;
        }
    },
})

export const { setDisplay, setCurrentBox } = navItemsSlice.actions
export default navItemsSlice.reducer
import React, { ReactNode } from 'react'
import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setCurrentBox } from '../store/slices/navItems';

interface Props {
    children?: ReactNode
}

const Layout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const dispatch = useAppDispatch();
    const error = useAppSelector((rootState) => rootState.error);
    const navItems = useAppSelector((rootState) => rootState.navItems);
    
    const clickHandler = (event: React.MouseEvent<HTMLLIElement>, id: number) => {
        event.preventDefault();
        dispatch(setCurrentBox(id));
    };

    return (
        <>
            {
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-start">
                            <a target="_blank" href="https://app.hubspot.com/">
                                <img className="logo" src={window.location.origin + '/hubspot_icon.svg'} />
                            </a>
                        </div>
                        {
                        navItems.show ?
                        <ul className="d-flex justify-content-end">
                            <li onClick={(event)=>clickHandler(event, 1)}>Note</li>
                            <li onClick={(event)=>clickHandler(event, 2)}>Ticket</li>
                            <li onClick={(event)=>clickHandler(event, 3)}>Task</li>
                        </ul> : 
                        <ul className="d-flex justify-content-end">
                            <li>&nbsp;</li>
                        </ul>
                        }
                    </div>
                </nav>
            }
            {error.errorMsg ?
                <main>
                    <div className="container-lg">
                        <Alert className='mt-4' variant="danger">{error.errorMsg}</Alert>
                    </div>
                </main>
                :
                <main>
                    {children}
                </main>
            }
        </>
    );
}

export default Layout;


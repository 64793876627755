import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DisplayCompanyInfos from '../components/company/DisplayCompanyInfos';
import DisplayContactInfos from '../components/contact/DisplayContactInfos';
import { CompanyDetails } from '../models/CompanyDetails';
import { ContactDetails } from '../models/ContactDetails';
import CompanyDataservice from '../services/Company.Dataservice';
import ContactDataservice from '../services/Contact.Dataservice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setError } from '../store/slices/errorSlice';
import { setLoading } from '../store/slices/loaderSlice';

import Layout from './Layout';

const Infos: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector((rootState) => rootState.credentials)
    let params = useParams();
    const contactId = params.contactId;
    const companyId = params.companyId;
    const showBackLink = params.showBackLink;
    const [contactDetail, setContact] = useState<ContactDetails | null>(null);
    const [companyDetail, setCompany] = useState<CompanyDetails | null>(null);

    useEffect(() => {
        getContact();
    }, [contactId, companyId, showBackLink]);

    const getContact = () => {
        dispatch(setLoading(true));
        if(contactId != null){
            ContactDataservice.getById(credentials.client, credentials.password, credentials.portalId, contactId).then((response) => {
                if(response != null){
                    setContact(response.data);
                }
                dispatch(setLoading(false));
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(e.message));
                dispatch(setLoading(false)); 
            }); 
        }
        if(companyId != null){
            CompanyDataservice.getById(credentials.client, credentials.password, credentials.portalId, companyId).then((responseCompany) => {
                if(responseCompany != null){
                    setCompany(responseCompany.data);
                }
                dispatch(setLoading(false));
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(e.message));
                dispatch(setLoading(false)); 
            });
        }
    }

    if(contactDetail != null){ 
        return (
            <Layout>
                <DisplayContactInfos contactDetails={contactDetail} showBackLink={showBackLink == "false" ? false : true}></DisplayContactInfos>
            </Layout>
        )
    }else if(companyDetail != null){ 
        return (
            <Layout>
                <DisplayCompanyInfos companyDetails={companyDetail} showBackLink={true}></DisplayCompanyInfos>
            </Layout>
        )
    }else{
        return (
            <Layout>
            </Layout>
        )
    }
}
export default Infos

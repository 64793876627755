import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContactDetails } from '../../models/ContactDetails';

const initialState: ContactDetails = {
    id: 0,
    portalId: 0,
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    postalCode: '',
    city: '',
    email: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    ownerId: 0,
    extendedProperties: {}
}

export const navContactDetailsSlice = createSlice({
    name: 'contactDetails',
    initialState,
    reducers: {
        setContactDetails: (state, action: PayloadAction<ContactDetails>) => {
            state.id = action.payload.id;
            state.portalId = action.payload.portalId;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.streetAddress = action.payload.streetAddress;
            state.postalCode = action.payload.postalCode;
            state.city = action.payload.city;
            state.email = action.payload.email;
            state.ownerId = action.payload.ownerId;
            state.extendedProperties = action.payload.extendedProperties;
        }
    },
})

export const { setContactDetails } = navContactDetailsSlice.actions
export default navContactDetailsSlice.reducer
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyDetails } from '../../models/CompanyDetails';

const initialState: CompanyDetails = {
    id: 0,
    portalId: 0,
    name: '',
    streetAddress: '',
    postalCode: '',
    city: '',
    extendedProperties: {}
}

export const navCompanyDetailsSlice = createSlice({
    name: 'companyDetails',
    initialState,
    reducers: {
        setCompanyDetails: (state, action: PayloadAction<CompanyDetails>) => {
            state.id = action.payload.id;
            state.portalId = action.payload.portalId;
            state.name = action.payload.name;
            state.lastName = action.payload.lastName;
            state.streetAddress = action.payload.streetAddress;
            state.postalCode = action.payload.postalCode;
            state.city = action.payload.city;
            state.extendedProperties = action.payload.extendedProperties;
        }
    },
})

export const { setCompanyDetails } = navCompanyDetailsSlice.actions
export default navCompanyDetailsSlice.reducer
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";
import PaymentLayout from "../../containers/landingpage/PaymentLayout";

const PaymentConfirmation: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const client = searchParams.get("client");
    const pass = searchParams.get("pass");
    const portalid = searchParams.get("portalid");
    const iframeUrl = `${process.env.REACT_APP_ROGER365_BASE_URL}powerframe?client=${client}&pass=${pass}&portalid=${portalid}&phonenumber={phonenumber}`
    const [butonText, setButtonText] = useState<string>();
    
    useEffect(() => {
        dispatch(setLoading(false));
        setButtonText("Copy \"PowerFrame\" URL");
    }, []);

    return (
        <PaymentLayout>
            <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12 col-lg-8">
                        <h1>Congratulations!</h1>
                        <p>
                            The installation of the connector was successful. In order for Roger365 to connect to HubSpot, you still need to make one setting:
                        </p>        
                    </div>
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <p>
                            <ol>
                                <li>Open your <a href="https://admin.roger365.io/" target="_blank">Roger365 Admin Portal</a>.</li>
                                <li>
                                    Go to the "Contact Center" tab and select "Queues".<br />
                                    <img src={require("../../assets/img/landingpage/roger365_menu.png")} className="img-fluid" style={{width: 200}} />
                                </li>
                                <li>
                                    Edit your queue<br />
                                    <img src={require("../../assets/img/landingpage/roger365_queues.png")} className="img-fluid" style={{border: "1px solid black"}} />
                                </li>
                                <li>
                                    Add your following personal URL (received by Mail) under "PowerFrame".<br />
                                    <img src={require("../../assets/img/landingpage/roger365_powerframe.png")} className="img-fluid" style={{width: 220, border: "1px solid black"}} />
                                </li>
                                <li>Repeat steps 3 and 4 for any additional queues.</li>
                            </ol>
                        </p>
                    </div>
                    <div className="col-12 col-lg-8" style={{wordWrap: "break-word"}}>
                        <p>
                            Your personal "PowerFrame" URL is: <br />
                            {iframeUrl.replaceAll("+", "%2B")}
                            <br/><br/>
                            <CopyToClipboard text={iframeUrl.replaceAll("+", "%2B")}>
                                <button className="btn-primary">{butonText}</button>
                            </CopyToClipboard>
                        </p>
                        <p>
                            If you have any questions, please contact us at <a href="mailto: support@chili.ch">support@chili.ch</a>.<br /><br />
                            Enjoy the benefits of our Roger365 Connector for HubSpot.
                        </p>
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default PaymentConfirmation;
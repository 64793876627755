import axios from "axios";

export function http(username: string, password: string) {
    return httpLocalized("", username, password);
}

export function httpLocalized(lang: string, username: string, password: string) {
    if(!lang) lang = "de";
    return(
        axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                "Content-type": "application/json",
                "Accept-Language": lang
            },
            auth: {
                username: username,
                password: password
            }
        })
    )
}


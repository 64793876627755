import { http } from "../http-common";
import { HubSpotNote } from "../models/HubSpotNote";

class NoteDataService {
    async save(client: string, password: string, portalId: string, hubSpotId: number, entityId: number, note: HubSpotNote) {
        if(note != null){
            const noteJson = JSON.stringify(note);
            return await http(client, password).post<HubSpotNote>(`/hubspot/portal/${portalId}/note?hubSpotId=${hubSpotId}&entityId=${entityId}`, noteJson);
        }
        return null;
    }
}
export default new NoteDataService();
import { http } from "../http-common";
import { Call } from "../models/Call";

class CallDataService {
    async save(client: string, password: string, portalId: string, hubSpotId: number, entityId: number, call: Call) {
        if(call != null){
            const callJson = JSON.stringify(call);
            return await http(client, password).post<Call>(`/hubspot/portal/${portalId}/call?hubSpotId=${hubSpotId}&entityId=${entityId}`, callJson);
        }
        return null;
    }
}
export default new CallDataService();
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setLoading } from "../../store/slices/loaderSlice";

import PaymentLayout from '../../containers/landingpage/PaymentLayout';

const Payment: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setLoading(false)); 
    }, []);

    return (
        <PaymentLayout>
            <div className="container">
                <div className="row content d-flex justify-content-center">
                    <div className="col-12">
                        <h1>Roger365 Connector for HubSpot</h1>
                    </div>
                    <div className="col-12 col-lg-8 text-center">
                        <p>Thank you for your interest in our Roger365 Connector for HubSpot.</p>
                        <p>
                            Next, we need your payment information. After that, you can download the app and the 14-day trial period will begin. After the trial period expires, the fee will be debited for the first time.
                        </p>
                        <p>
                            Please choose if you want to pay monthly or yearly.<br /><br />
                            <div className="row d-flex justify-content-center">
                                <div className="col-12 mb-4 mb-md-0 col-md-5"><a className="btn-primary" role="button" href={process.env.REACT_APP_PAYMENT_LINK_MONTHLY}>89.00 € per month</a></div>
                                <div className="col-12 col-md-5"><a className="btn-primary" role="button" href={process.env.REACT_APP_PAYMENT_LINK_YEARLY}>980.00 € per year</a></div>
                            </div>
                            <br /><br />
                            Don't have Roger365 or HubSpot yet? <a href="https://www.chili.ch/roger365-contactcenter" target="_blank">Here</a> you can find more information about it.
                        </p>
                    </div>
                </div>
            </div>
        </PaymentLayout>
    )
}
export default Payment;
import { http } from "../http-common";
import { CompanyDetails } from "../models/CompanyDetails";

class CompanyDataService {
    async get(client: string, password: string, portalId: string, phoneNumber: string) {
        if(phoneNumber != null){
            phoneNumber = phoneNumber.replace("tel:", "");
            return await http(client, password).get<Array<CompanyDetails>>(`/hubspot/portal/${portalId}/company/phonenumber/${phoneNumber}`);
        }
        return null;
    }

    async getById(client: string, password: string, portalId: string, companyId: string ) {
        return await http(client, password).get<CompanyDetails>(`/hubspot/portal/${portalId}/company/${companyId}`);
    }
}
export default new CompanyDataService();